import { CustomerType } from "@origin-digital/platform-enums";
import { BasicJSONSchema, IMcAppType, NavFlow, Tab } from "../mcApp.types";

const voucherId: BasicJSONSchema = {
  description: "hashed fuel voucher id",
  type: "string",
};

export const rewardBarcode_KRAKEN: IMcAppType = {
  name: "rewardBarcode_KRAKEN",
  path: "/my/reward/barcode",
  parameters: {
    required: ["voucherId"],
    properties: { voucherId },
  },
  description: "Reward barcode for fuel discount",
  navFlow: NavFlow.MODAL,
  title: "EG Fuel offer",
  parent: Tab.REWARDS,
  conditions: {
    intent: "rewardBarcode",
    customerType: CustomerType.kraken,
  },
};

export const reward_KRAKEN: IMcAppType = {
  name: "reward_KRAKEN",
  path: "/my/reward",
  description: "Reward page for fuel discount",
  navFlow: NavFlow.TAB,
  title: "EG Fuel offer",
  parent: Tab.REWARDS,
  conditions: {
    intent: "reward",
    customerType: CustomerType.kraken,
  },
};

export const rewardCoffee_KRAKEN: IMcAppType = {
  name: "rewardCoffee_KRAKEN",
  path: "/my/reward/coffee",
  description: "Origin Coffee landing page",
  navFlow: NavFlow.FOCUS,
  title: "7-Eleven Coffee Offer",
  parent: Tab.REWARDS,
  conditions: {
    intent: "rewardCoffee",
    customerType: CustomerType.kraken,
  },
};

export const rewardCoffeeBarcode_KRAKEN: IMcAppType = {
  name: "rewardCoffeeBarcode_KRAKEN",
  path: "/my/reward/coffee/barcode",
  description: "Reward barcode for coffee discount",
  navFlow: NavFlow.MODAL,
  title: "7-Eleven Coffee Offer",
  parent: Tab.REWARDS,
  conditions: {
    intent: "rewardCoffeeBarcode",
    customerType: CustomerType.kraken,
  },
};

export const rewardMovies_KRAKEN: IMcAppType = {
  name: "rewardMovies_KRAKEN",
  path: "/my/reward/movies",
  description: "Origin Movies landing page",
  navFlow: NavFlow.FOCUS,
  title: "Origin Movies",
  parent: Tab.REWARDS,
  conditions: {
    intent: "rewardMovies",
    customerType: CustomerType.kraken,
  },
};

export const fuelRewardBarcode_KRAKEN: IMcAppType = {
  name: "fuelRewardBarcode_KRAKEN",
  path: "/my/reward/fuel/barcode",
  parameters: {
    required: ["voucherId"],
    properties: { voucherId },
  },
  description: "Reward barcode for fuel discount",
  navFlow: NavFlow.MODAL,
  title: "EG Fuel offer",
  parent: Tab.REWARDS,
  conditions: {
    intent: "fuelRewardBarcode",
    customerType: CustomerType.kraken,
  },
};

export const fuelReward_KRAKEN: IMcAppType = {
  name: "fuelReward_KRAKEN",
  path: "/my/reward/fuel",
  description: "Reward page for fuel discount",
  navFlow: NavFlow.STANDARD,
  title: "EG Fuel offer",
  parent: Tab.REWARDS,
  conditions: {
    intent: "fuelReward",
    customerType: CustomerType.kraken,
  },
};

export const freeEnergyHour: IMcAppType = {
  name: "freeEnergyHour",
  path: "/my/reward/feh",
  description: "Energy Happy Hours landing page",
  navFlow: NavFlow.STANDARD,
  title: "Energy Happy Hours",
  parent: Tab.REWARDS,
  conditions: {
    intent: "freeEnergyHour",
    customerType: CustomerType.kraken,
  },
};

export const freeEnergyHourBooking: IMcAppType = {
  name: "freeEnergyHourBooking",
  path: "/my/reward/feh/booking",
  description: "Energy Happy Hours booking page",
  navFlow: NavFlow.FOCUS,
  title: "Book an Energy Happy Hour",
  parent: Tab.REWARDS,
  conditions: {
    intent: "freeEnergyHourBooking",
    customerType: CustomerType.kraken,
  },
};
